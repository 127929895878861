import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import MainBg from "../components/mainBg";
import Callout from "../components/callout";
import DidYouKnow from "../components/didYouKnow";
import NewsletterSignup from "../components/newsletterSignup";
import CallToAction from "../components/callToAction";

class SmallBusinessOwnersPolicy extends React.Component {
  render() {
    
    return (
      <Layout location={this.props.location}>
        <Helmet
          htmlAttributes={{ lang: "en" }}
          title="Small Business Owner's Policy - Baton Rouge | Lewis Mohr"
          meta={[{ name: "description", content: "A Small Business Owner’s Policy (BOP) bundles General Liability and Commercial Property Insurance into an affordable package for your small business in Louisiana." }]}  
        />
        <MainBg mainBg={this.props.data.mainBg} imgStyle={{objectPosition: 'top'}} alt="Small business owner behind counter in Baton Rouge, Louisiana" />
        <Callout
          title="Small Business Owner's Policy"
          content="Have a straight forward small business in Baton Rouge, Louisiana? A Small Business Owner’s Policy (BOP) could be right for you because it bundles General Liability and Commercial Property Insurance into an affordable package."
        />
        <div className="site-body page-content">
          <h2>What Small Business Owner’s Policies Usually Covers</h2>
          <p>
          <ul>
          <li><strong>Third-Party Bodily Injury.</strong> When a customer slips and falls or gets hurt on your property, this helps cover medical and legal expenses if you are sued.</li>
          <li><strong>Third-Party Property Damage.</strong> If you damage a customer's property, such as a smartphone or laptop, this helps pay for repair or replacement costs.</li>
          <li><strong>Product Liability.</strong> If your product hurts someone, such as a hot coffee burn, this will help pay for legal expenses.</li>
          <li><strong>Advertising Liability.</strong> If you are sued over slander or copyright claims this will help pay for your legal defense.</li>
          <li><strong>Company Buildings.</strong> This covers the repair of the structure and will sometimes include installed fixtures and machinery.</li>
          <li><strong>Contents of Buildings.</strong> This normally includes things that can be easily removed such as inventory, office equipment and supplies.</li>
          <li><strong>Other Property.</strong> This includes items that are left by someone else in your care or control.</li>
        </ul>
          </p>
          <DidYouKnow
            fact="More than 40 percent of small businesses will experience an insurance claim in the next 10 years."
            source="The Hartford"
          />
          <h2>What Small Business Owner’s Policies Usually Do Not Cover</h2>
          <p>
          <ul>
          <li>Anything that is not covered under typical General Liability and Commercial Property Insurance policies.</li>
        </ul>
          </p>
        </div>
        <CallToAction bg={this.props.data.callToActionBg} type="a Small Business Owner's Policy" />
        <NewsletterSignup />
      </Layout>
    );
  }
}

export default SmallBusinessOwnersPolicy;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    mainBg: file(relativePath: { eq: "small-business-owners-policy-bg.jpg" }) {
      ...mainBg
    }
    callToActionBg: file(relativePath: { eq: "bg-cta-16.jpg" }) {
      ...mainBg
    }
  }
`;
